import { RouteObject } from 'react-router';
import { ErrorBoundary } from '@brunas/dashboard';

import Products from './Products';
import Orders from './Orders';
import Order from './Order';
import Usage from './Billing/Usage';
import Invoices from './Billing/Invoices';
import Settings from './Billing/Settings';
import Users from './Users';
import User from './User';
import Account from './Account';

export const ROUTES: RouteObject[] = [
  {
    index: true,
    element: (
      <ErrorBoundary>
        <Usage />
      </ErrorBoundary>
    ),
  },
  {
    path: 'account',
    element: (
      <ErrorBoundary>
        <Account />
      </ErrorBoundary>
    ),
  },
  {
    path: 'products',
    element: (
      <ErrorBoundary>
        <Products />
      </ErrorBoundary>
    ),
  },
  {
    path: 'orders',
    children: [
      {
        index: true,
        element: (
          <ErrorBoundary>
            <Orders />
          </ErrorBoundary>
        ),
      },
      {
        path: ':id',
        element: (
          <ErrorBoundary>
            <Order />
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: 'billing',
    children: [
      {
        path: 'usage',
        element: (
          <ErrorBoundary>
            <Usage />
          </ErrorBoundary>
        ),
      },
      {
        path: 'invoices',
        element: (
          <ErrorBoundary>
            <Invoices />
          </ErrorBoundary>
        ),
      },
      {
        path: 'settings',
        element: (
          <ErrorBoundary>
            <Settings />
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        index: true,
        element: (
          <ErrorBoundary>
            <Users />
          </ErrorBoundary>
        ),
      },
      {
        path: ':id',
        element: (
          <ErrorBoundary>
            <User />
          </ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <div>Not found</div>,
  },
];
