import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import * as yup from 'yup';
import {
  SimpleChipInput,
  useForm,
  useNotification,
  FormProvider,
} from '@brunas/dashboard';

import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import RolesInput from 'components/RolesInput';
import AddUserDialogActions from 'components/AddUserDialogActions';

import { Response } from 'lib';
import { useCreateUser, userKeys } from 'features';
import { useDialog } from '@brunas/dashboard/dist/Dialog';
import { Dialog } from '@mui/material';

export type FormValues = {
  emails: string[];
  roles: string[];
};

const defaultValues = {
  emails: [],
  roles: [],
};

type Props = {};

type ErrorOutcome = {
  email: string;
  message: string;
};

const AddUsersDialog: FC<Props> = () => {
  const { close } = useDialog();

  const [creationErrors, setCreationErrors] = useState<ErrorOutcome[]>([]);
  const { t } = useTranslation(['AddUsersDialog', 'BackendErrors']);
  const { pop } = useNotification();
  const queryClient = useQueryClient();
  const alert = useRef<HTMLDivElement>(null);
  const emailsInput = useRef<HTMLInputElement>(null);

  const schema = useMemo(
    () =>
      yup.object().shape({
        emails: yup
          .array()
          .required()
          .min(1, t('AddUsersDialog:AT_LEAST_ONE_EMAIL_ADDRESS'))
          .of(yup.string().email(t('AddUsersDialog:INCORRECT_EMAIL_ADDRESS'))),
        roles: yup.array().of(yup.string()),
      }),
    [t]
  );

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const { isLoading, mutateAsync } = useCreateUser();

  const submit = useCallback(
    ({ emails, roles }: FormValues) => {
      const successes: string[] = [];
      const errors: ErrorOutcome[] = [];

      const calls = emails.map(email =>
        mutateAsync({ email, roles })
          .then(() => {
            successes.push(email);
          })
          .catch((error: AxiosError<Response<any>>) => {
            errors.push({
              email: JSON.parse(error.config.data).email,
              message: error.response?.data.message
                ? t(`BackendErrors:${error.response.data.message}`)
                : t('AddUsersDialog:USER_CREATION_FAILED'),
            });

            alert.current?.scrollIntoView();
          })
      );

      Promise.all(calls).then(() => {
        queryClient.invalidateQueries(userKeys.lists());
        setCreationErrors(errors);

        if (successes.length > 0) {
          form.setValue(
            'emails',
            errors.map(({ email }) => email)
          );
          pop(`${t('USER_CREATED')}: ${successes.join(', ')}`, 'success');
        }

        if (errors.length === 0) {
          close();
        }
      });
    },
    [close, form, mutateAsync, pop, queryClient, t]
  );

  return (
    <Dialog open={true} scroll={"body"}>
    <FormProvider {...form}>
      <DialogTitle>{t('ADD_USERS')}</DialogTitle>

      <DialogContent dividers>
        <form>
          {creationErrors.length > 0 && (
            <Alert severity="error" sx={{ mb: 2 }} ref={alert}>
              {creationErrors.map(({ email, message }) => (
                <Box key={email}>
                  {email}: {t(message)}
                </Box>
              ))}
            </Alert>
          )}

          <SimpleChipInput
            ref={emailsInput}
            label={t('EMAIL_ADDRESSES')}
            helperText={t('EMAIL_ADDRESSES_HELP_TEXT')}
            name="emails"
          />

          <RolesInput name="roles" />
        </form>
      </DialogContent>

      <AddUserDialogActions loading={isLoading} onSubmit={submit} />
    </FormProvider>
    </Dialog>
  );
};

export default AddUsersDialog;
