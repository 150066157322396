import { FC, useCallback, useState } from 'react';
import { DatePickerBase } from '@brunas/dashboard';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Section from 'components/Section';
import Breadcrumbs from 'components/Breadcrumbs';
import BillingUsageTable from 'components/BillingUsageTable';
import BillingUsageGraph from 'components/BillingUsageGraph';
import BillingUsageSkeleton from 'components/BillingUsageSkeleton';

import { useUsage, PeriodType } from 'features';
import Alert from '@mui/material/Alert';

type State = {
  from: Date;
  to: Date;
  periodType: PeriodType;
};

const to = new Date();

const from = new Date(to.getTime());
from.setMonth(to.getMonth() - 6);
from.setDate(1)


const initial: State = {
  from,
  to,
  periodType: 'month',
};

const Usage: FC = () => {
  const { t } = useTranslation('Usage');
  const [state, setState] = useState<State>(initial);
  const query = useUsage(state);

  const handleDateChange = useCallback(
    (name: string) => (value: Date | null) => {
      setState(current => ({ ...current, [name]: value }));
    },
    []
  );

  const setPeriodType = useCallback(
    (periodType: PeriodType) => () => {
      setState(current => ({ ...current, periodType }));
    },
    []
  );

  return (
    <>
      <Breadcrumbs breadcrumbs={[{ label: 'BILLING' }, { label: 'USAGE' }]} />

      <Section sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
        <DatePickerBase
          label={t('FROM')}
          value={state.from}
          onChange={handleDateChange('from')}
          sx={{ mr: 2 }}
        />

        <DatePickerBase
          label={t('TO')}
          value={state.to}
          onChange={handleDateChange('to')}
          sx={{ mr: 2 }}
        />

        <Box sx={{ mt: 1 }}>
          <Button
            variant={state.periodType === 'day' ? 'contained' : 'outlined'}
            onClick={setPeriodType('day')}
            sx={{ mr: 1 }}>
            {t('DAILY')}
          </Button>

          <Button
            variant={state.periodType === 'week' ? 'contained' : 'outlined'}
            onClick={setPeriodType('week')}
            sx={{ mr: 1 }}>
            {t('WEEKLY')}
          </Button>

          <Button
            variant={state.periodType === 'month' ? 'contained' : 'outlined'}
            onClick={setPeriodType('month')}
            sx={{ mr: 1 }}>
            {t('MONTHLY')}
          </Button>
        </Box>
      </Section>

      {query.data ? (
        query.data.length > 0 ? (
          <>
            <BillingUsageGraph usage={query.data} />
            <BillingUsageTable usage={query.data} />
          </>
        ) : (
          <Alert severity="info" variant="filled">
            {t('NO_USAGE')}
          </Alert>
        )
      ) : (
        <BillingUsageSkeleton />
      )}
    </>
  );
};

export default Usage;
